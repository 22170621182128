<template>
  <v-card>
      <v-toolbar color="color2 color2Text--text">
        <v-toolbar-title>Organization Wizard</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn color="color2Text" text icon @click="cancel"><v-icon>fas fa-times</v-icon></v-btn>
      </v-toolbar>
      <template v-if="!deny">
        <loading-bar v-model="loading"></loading-bar>
        <!-- TITLE ROW -->
        <v-card-title class="title font-weight-regular justify-space-between">
          <span>{{ currentTitle }}</span>
          <v-avatar
            v-if="window !== LOAD && window !== DONE"
            color="color3"
            class="color3Text--text"
            size="24"
            v-text="window"
          ></v-avatar>
        </v-card-title>
        <v-window v-model="window" v-if="active" touchless>
          <!-- LOAD -->
          <v-window-item :value="LOAD">
            <v-card-text class="pt-0">
              <div class="subheading pb-4">To edit an organization you have already created select it from below then click the forward button</div>
              <v-select
                id="organization"
                name="organization"
                :items="user.pages"
                v-model="selectedPage"
                label="Select An Organization"
                item-text="name"
                item-value="username"
                clearable
                color="color3"
                item-color="color3"
              ></v-select>
            </v-card-text>
          </v-window-item>
          <!-- NAME -->
          <v-window-item :value="NAME">
            <v-card-text class="pt-0">
              <v-text-field
                label="Organization Name*"
                v-model="organization.name"
                hint="Enter the name of your organization"
                persistent-hint
                :disabled="loading"
                @blur="$v.organization.name.$touch()"
                :error-messages="nameErrors"
                color="color3"
              ></v-text-field>
              <v-text-field
                class="mt-4"
                label="Page User Name*"
                v-model="organization.username"
                :hint="usernameHint"
                persistent-hint
                :append-icon="usernameIcon"
                :color="available ? 'success' : 'color3'"
                :disabled="loading || organization.id > 0"
                @blur="usernameBlur"
                @input="cleanUsername"
                @keydown.space.prevent
                :error-messages="usernameErrors"
              ></v-text-field>
              <v-checkbox v-model="organization.agree" :disabled="organization.id > 0" color="color3">
                <span slot="label">I agree and consent to the <a href="/terms" target="_blank">Terms of Service</a> and <a href="/privacy" target="_blank">Privacy Policy</a></span>
              </v-checkbox>
            </v-card-text>
          </v-window-item>
          <!-- CONTACT -->
          <v-window-item :value="CONTACT">
            <v-card-text class="pt-0">
              <div class="subheading pb-4">Please list a public email and phone number that we can publish for any questions or concerns with your events.</div>
              <v-text-field
                name="Email"
                label="Public Email Address*"
                id="email"
                v-model="organization.email"
                @blur="$v.organization.email.$touch()"
                :error-messages="emailErrors"
                color="color3"
              ></v-text-field>
              <v-text-field
                class="mt-4"
                name="Phone"
                label="Public Phone Number*"
                id="phone"
                v-model="organization.phone"
                v-mask="'(###) ###-####'"
                @blur="$v.organization.phone.$touch()"
                :error-messages="phoneErrors"
                color="color3"
              ></v-text-field>
            </v-card-text>
          </v-window-item>
          <!-- STRIPE -->
          <v-window-item :value="STRIPE">
            <v-card-text class="pt-0">
              Volleyball Life processes tournament registration payments through Stripe Cloud Payments Platform.
              The payments are processed by our platform, on your behalf, as direct charges to your Stripe account.
              The full charge amount - less the <a href="https://stripe.com/us/pricing" target="_blank">Stripe fees</a>
              and our transaction fee - is deposited directly into your Stripe account.
              <div class="title text-center pa-2" v-if="connected">You're connected!</div>
            </v-card-text>
            <v-card-text class="pt-0" v-if="!connected">
              Simply click the Connect with Stripe button below.
              A new window will open and walk you through setting up an account or connecting your existing account.
            </v-card-text>
          </v-window-item>
          <!-- STRIPE SKIP -->
          <v-window-item :value="SKIP_STRIPE">
            <v-card-text>
              <v-alert type="warning" :value="true" class="black--text">
                You may connect a Stripe account later but, you will not be able to take registration until you do.
              </v-alert>
            </v-card-text>
          </v-window-item>
          <!-- LOGO -->
          <v-window-item :value="LOGO">
            <template v-if="organization && organization.username">
              <v-card-text class="subheading pt-0">
                Your logo will appear next to each of your tournaments in our nation wide tournament list as well
                as the toolbar of your branded web site at https://{{organization.username}}.volleyballlife.com
                <div class="text-center" v-if="organization.theme && organization.theme.logoUrl">
                  <div class="title pa-2">Your logo is all set!</div>
                  <img :src="organization.theme.logoUrl" alt="logo" height="64">
                  <div>You can change your logo at anytime by clicking the images tab in the navigation menu, on the left.</div>
                </div>
              </v-card-text>
              <image-library
                v-if="!organization.theme || !organization.theme.logoUrl"
                :username="organization.username"
                :oId="organization.id"
                ref="library"
                noPreview
                singleRow
                :imgWidth="192"
                :imgHeight="64"
                :disabled="loading"
                center
                logo
                uploadOnly
                @logo-saved="onLogoSaved"
                @has-unsaved-image="setUnsavedImage"
              />
              <div class="text-center" v-if="dirtyLogo">(Click upload to set this as you logo or cancel to change images)</div>
            </template>
          </v-window-item>
          <!-- DETAILS -->
          <v-window-item :value="DETAILS">
            <v-card-text>
              <v-text-field
                name="Website"
                label="Website"
                id="website"
                v-model="organization.websiteUrl"
                color="color3"
              ></v-text-field>
              <v-text-field
                name="Facebook"
                label="Facebook"
                id="facebook"
                v-model="organization.facebook"
                color="color3"
              ></v-text-field>
              <v-text-field
                name="instagram"
                label="Instagram"
                id="instagram"
                v-model="organization.instagram"
                color="color3"
              ></v-text-field>
              <v-text-field
                name="twitter"
                label="Twitter"
                id="twitter"
                v-model="organization.twitter"
                color="color3"
              ></v-text-field>
            </v-card-text>
          </v-window-item>
          <!-- THEME -->
          <v-window-item :value="THEME">
            <theme-editor
              :username="organization.username"
              :triggerLoad="window === THEME"
              ref="theme"
              :inDialog="true"
              v-if="organization.id > 0"
              @dirty-change="onThemeDirtyChange"
            ></theme-editor>
          </v-window-item>
          <!-- DONE -->
          <v-window-item :value="DONE">
            <v-card-text class="subheading pt-0">
              Everything we have done here, or any steps you skipped, can be edited at any time.
              You now have links in the navigation menu (on the left) to all of your organization settings, images, and theme.
            </v-card-text>
            <v-card-text class="subheading pt-0 text-center">
              <v-btn
                color="color3 color3Text--text"
                @click.stop="cancel"
              >Go to dashboard</v-btn>
            </v-card-text>
          </v-window-item>
        </v-window>
        <!-- BUTTONS -->
        <v-divider></v-divider>
        <v-card-actions v-if="window !== STRIPE || connected">
          <a
            v-if="window === NAME && user.pages && user.pages.length"
            @click.stop="window = LOAD"
            style="vertical-align: bottom; font-size: smaller"
          >Need to edit an organizaton?</a>
          <!-- BACK -->
          <v-fab-transition>
            <v-btn
              color="color3Text color3--text"
              small
              fab
              v-if="window > NAME"
              :disabled="dirty || !!dirtyLogo || dirtyTheme"
              @click="onBackClick"
            ><v-icon>fas fa-caret-left</v-icon></v-btn>
          </v-fab-transition>
          <v-spacer></v-spacer>
          <!-- UNDO -->
          <v-fab-transition>
            <v-btn
              v-if="dirty"
              color="error errorText--text"
              small
              fab
              @click="undo"
            ><v-icon>fas fa-undo-alt</v-icon></v-btn>
          </v-fab-transition>
          <!-- NEXT -->
          <v-fab-transition>
            <v-btn
              v-if="window !== DONE"
              color="color3 color3Text--text"
              small
              fab
              :disabled="!valid"
              :loading="valid && loading"
              @click="onNextClick"
            ><v-icon>fas fa-caret-right</v-icon></v-btn>
          </v-fab-transition>
        </v-card-actions>
        <v-card-actions v-else>
          <v-fab-transition>
            <v-btn
              color="color3Text color3--text"
              small
              fab
              v-if="window > NAME"
              @click="onBackClick"
            ><v-icon>fas fa-caret-left</v-icon></v-btn>
          </v-fab-transition>
          <v-spacer></v-spacer>
          <v-fab-transition>
            <div>
              <stripe-connect :organization-id="organization.id" :url="organization.connectUrl"></stripe-connect>
              <a @click.stop="onNextClick" style="vertical-align: bottom; font-size: smaller" class="ml-4">I'll do this later</a>
            </div>
          </v-fab-transition>
        </v-card-actions>
      </template>
      <template v-else>
        <v-card-text class="title">
          Please verify your email address before creating an organization.
        </v-card-text>
        <v-card-text>
          An email was sent to you when you first created your account that contained a verification link. If you can not find it or it is not working, go to your
          <a class="color3--text" @click="profileClick">My Profile</a> page and click the settings icon next to your email address.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="color3" text @click.stop="$emit('cancel-click')">Got It</v-btn>
        </v-card-actions>
      </template>
  </v-card>
</template>

<script>
import debounce from 'lodash.debounce'
import { mapGetters } from 'vuex'
import * as mutations from '@/store/MutationTypes'
import ImageLibrary from './OrganizationImageLibrary.vue'
import StripeConnect from '@/components/Organization/StripeConnect.vue'
import { validationMixin } from 'vuelidate'
import { required, email } from 'vuelidate/lib/validators'
import ThemeEditor from '@/components/Organization/ThemeEditor.vue'
import { mask } from 'vue-the-mask'
import ValidUSPhone from '@/helpers/ValidUSPhone'

export default {
  directives: { mask },
  props: ['username', 'start', 'active'],
  mixins: [validationMixin],
  validations () {
    return {
      organization: {
        name: { required },
        username: { required },
        email: { required, email },
        phone: { required, ValidUSPhone }
      }
    }
  },
  data () {
    return {
      window: 1,
      checking: false,
      available: null,
      loading: false,
      agree: false,
      stripeAccounts: [],
      LOAD: -1,
      NAME: 1,
      CONTACT: 2,
      STRIPE: 3,
      SKIP_STRIPE: 4,
      LOGO: 5,
      DETAILS: 6,
      THEME: 7,
      DONE: 8,
      selectedPage: null,
      odto: null,
      hasUnsavedImage: false,
      themeIsDirty: false
    }
  },
  computed: {
    ...mapGetters(['getPageInfo', 'user', 'organization']),
    deny () {
      return !this.user.verifiedEmails.length
    },
    valid () {
      switch (this.window) {
        case this.NAME:
          return !this.$v.organization.name.$invalid && !this.$v.organization.username.$invalid && (this.available || this.organization.id > 0) && this.organization.agree
        case this.CONTACT:
          return !this.$v.organization.email.$invalid && !this.$v.organization.phone.$invalid
        case this.LOGO:
          return !this.dirtyLogo
        case this.THEME:
          return !this.dirtyTheme
        default:
          return true
      }
    },
    currentTitle () {
      switch (this.window) {
        case this.LOAD:
          return 'Edit Existing Organization'
        case this.NAME:
          return 'Name & Username'
        case this.CONTACT:
          return 'Customer Support'
        case this.STRIPE:
          return 'Payment Processing'
        case this.SKIP_STRIPE:
          return 'Warning!'
        case this.LOGO:
          return 'Logo Upload'
        case this.DETAILS:
          return 'Website & Social Media'
        case this.THEME:
          return 'Theme Your Site'
        case this.DONE:
          return 'Welcome To Volleyball Life!'
      }
      return null
    },
    currentRoute () {
      const u = this.organization.username
      if (!u) return null
      const dash = { name: 'organization-home', params: { username: u } }
      const settings = { name: 'organization-settings', params: { username: u } }
      const theme = { name: 'organization-theme', params: { username: u } }
      const images = { name: 'organization-images', params: { username: u } }
      switch (this.window) {
        case this.LOAD:
          return null
        case this.NAME:
          return this.organization.id > 0 ? settings : null
        case this.CONTACT:
          return this.organization.id > 0 ? settings : null
        case this.STRIPE:
          return this.organization.id > 0 ? settings : null
        case this.SKIP_STRIPE:
          return this.organization.id > 0 ? settings : null
        case this.LOGO:
          return this.organization.id > 0 ? images : null
        case this.DETAILS:
          return this.organization.id > 0 ? settings : null
        case this.THEME:
          return this.organization.id > 0 ? theme : null
        case this.DONE:
          return this.organization.id > 0 ? dash : null
      }
      return null
    },
    nameErrors () {
      const errors = []
      if (!this.$v.organization.name.$dirty) return errors
      !this.$v.organization.name.required && errors.push('A name is required')
      return errors
    },
    usernameErrors () {
      const errors = []
      if (!this.$v.organization.username.$dirty) return errors
      !this.$v.organization.username.required && errors.push('A username is required')
      this.available === false && errors.push(`${this.organization.username}.volleyballlife.com is unavailable`)
      return errors
    },
    emailErrors () {
      const errors = []
      if (!this.$v.organization.email.$dirty) return errors
      !this.$v.organization.email.required && errors.push('An email is required')
      !this.$v.organization.email.email && errors.push('A valid email is required')
      return errors
    },
    phoneErrors () {
      const errors = []
      if (!this.$v.organization.phone.$dirty) return errors
      !this.$v.organization.phone.required && errors.push('A phone is required')
      !this.$v.organization.phone.ValidUSPhone && errors.push('A valid phone is required')
      return errors
    },
    usernameHint () {
      return !this.organization.username
        ? 'Enter a username for your organization\'s page(s)'
        : this.checking
          ? `Checking availability of: ${this.organization.username}.volleyballlife.com`
          : `${this.organization.username}.volleyballlife.com`
    },
    usernameIcon () {
      return this.available === null ? '' : this.available ? 'fas fa-check-circle' : 'fas fa-times-circle'
    },
    dirty () {
      return JSON.stringify(this.patchDto) !== JSON.stringify(this.odto)
    },
    dto () {
      return this.organization.dto
    },
    patchDto () {
      if (this.organization.id === 0) return null
      switch (this.window) {
        case this.NAME:
          return {
            name: this.organization.name
          }
        case this.CONTACT:
          return {
            email: this.organization.email,
            phone: this.organization.phone
          }
        case this.DETAILS:
          return {
            websiteUrl: this.organization.websiteUrl,
            facebook: this.organization.facebook,
            instagram: this.organization.instagram,
            twitter: this.organization.twitter
          }
        default:
          return null
      }
    },
    connected () {
      return this.organization.connected
    },
    dirtyLogo () {
      return this.window === this.LOGO && this.hasUnsavedImage
    },
    dirtyTheme () {
      return this.window === this.THEME && this.themeIsDirty
    }
  },
  methods: {
    cleanUsername () {
      this.$nextTick(() => {
        if (this.organization.username) {
          this.organization.username = this.organization.username.replace(/\W/g, '')
        }
      })
    },
    usernameBlur () {
      this.cleanUsername()
      this.$v.organization.username.$touch()
    },
    profileClick () {
      this.$router.push({ name: 'me' })
      this.$emit('cancel-click')
    },
    onBackClick () {
      let a = 1
      if (this.window === this.SKIP_STRIPE + 1 && this.connected) a++
      this.window -= a
    },
    async onNextClick () {
      if (this.dirty && this.patchDto) {
        await this.patch()
      }
      let a = 1
      if (this.window === this.LOAD) {
        if (this.selectedPage) {
          this.load()
          return
        }
        this.$store.commit(mutations.SET_ORGANIZATION)
        this.window = this.NAME
        return
      }
      if (this.window === this.CONTACT && this.organization.id === 0) {
        this.create()
        return
      }
      if (this.window === this.STRIPE && this.connected) a++
      this.window += a
    },
    checkAvailability: debounce(function () {
      this.usernameLookUp()
    }, 500),
    usernameLookUp () {
      if (!this.organization.username) return
      if (this.organization.id > 0) {
        const o = this.getPageInfo(this.organization.id)
        if (o && o.username.toLowerCase() === this.organization.username.toLowerCase()) {
          this.available = true
          return
        }
      }
      this.checking = true
      this.$VBL.organization.getUsernameAvailability(this.organization.username)
        .then((response) => {
          this.available = response.data
          this.$v.organization.username.$touch()
          this.checking = false
        })
        .catch((error) => {
          console.log(error.response)
          this.checking = false
        })
    },
    onConnectClick () {
      this.create(true)
    },
    onSkipConfirm () {
      this.create()
    },
    load () {
      if (this.selectedPage === this.organization.username) {
        this.window = this.NAME
        return
      }
      this.loading = true
      this.$VBL.organization.get(this.selectedPage)
        .then((response) => {
          response.data.agree = true
          this.$store.commit(mutations.SET_ORGANIZATION, response.data)
          this.window = this.NAME
        })
        .catch(err => {
          console.log(err.response)
        })
        .finally(() => {
          this.loading = false
        })
    },
    create () {
      this.loading = true
      this.$VBL.organization.post(this.organization.dto)
        .then((response) => {
          this.organization.update(response.data)
          this.window++
        })
        .catch((error) => {
          console.log(error.response)
          if (error.response.data && error.response.data.startsWith('Username')) {
            this.available = false
            this.$v.organization.username.$touch()
            this.window = 1
          }
        })
        .finally(() => { this.loading = false })
    },
    patch () {
      return new Promise((resolve, reject) => {
        this.loading = true
        const dto = Object.assign({ username: this.organization.username, id: this.organization.id }, this.patchDto)
        this.$VBL.organization.post(dto)
          .then((response) => {
            this.organization.update(response.data)
            return resolve()
          })
          .catch((e) => {
            console.log(e.response)
            return reject(e)
          })
          .finally(() => { this.loading = false })
      })
    },
    cancel () {
      this.$store.commit(mutations.SET_ORGANIZATION)
      this.$v.$reset()
      if (this.$refs.library) this.$refs.library.unsavedImage = null
      if (this.$refs.theme) this.$refs.theme.cancelPreview()
      this.$emit('cancel-click')
      this.window = this.NAME
      this.odto = null
    },
    undo () {
      this.organization.update(this.odto)
    },
    onLogoSaved (dto) {
      this.organization.update(dto)
    },
    setUnsavedImage (val) {
      this.hasUnsavedImage = val
      this.$emit('has-unsaved-image', !!val)
    },
    onThemeDirtyChange (val) {
      this.themeIsDirty = val
    }
  },
  watch: {
    'organization.username': function (val) {
      this.available = null
      this.checkAvailability()
    },
    organization: function (val) {
      if (val && val.id > 0) {
        this.agree = true
      }
    },
    start: function (val) {
      if (val) this.window = val
    },
    window: function (val) {
      if (val === this.LOAD) {
        this.selectedPage = this.organization.username
      }
      if (val === this.THEME && this.$refs.theme) {
        this.$refs.theme.cancelPreview()
      }
      this.odto = this.patchDto ? Object.assign({}, this.patchDto) : null
    },
    currentRoute: function (n, o) {
      if (n && this.active) {
        this.$router.push(n)
          .catch(() => { console.log('here') })
      }
    }
  },
  components: {
    ImageLibrary,
    StripeConnect,
    ThemeEditor
  }
}
</script>

<style scoped>
/deep/ .v-btn--right {
  right: -20px !important;
}
</style>
