<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="500px"
  >
    <template v-slot:activator="{ on }">
      <v-btn v-on="on">Are you a tournament Director?</v-btn>
    </template>
    <onboard-wizard @cancel-click="dialog = false" :active="dialog"></onboard-wizard>
  </v-dialog>
  <!-- <v-dialog
    v-model="dialog"
    persistent
    max-width="500px"
  >
    <template v-slot:activator="{ on }">
      <v-btn v-on="on">Are you an Indoor Club Director?</v-btn>
    </template>
    <indoor-club-wizard @cancel-click="dialog = false" :active="dialog"></indoor-club-wizard>
  </v-dialog> -->
</template>

<script>
import OnboardWizard from './OnboardingWizard.vue'
// import IndoorClubWizard from './IndoorClubWizard.vue'
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      dialog: false
    }
  },
  computed: {
    ...mapGetters(['getPageInfo']),
    openByQuery () {
      return this.$route.query && this.$route.query.onboarding && this.getPageInfo(this.$route.query.onboarding)
    }
  },
  methods: {
    load () {
      // this.$store.commit(mutations.LOADING, `ORG_PROFILE_${this.openByQuery}`)
      this.$VBL.organization.get(this.openByQuery.username)
        .then((response) => {
          this.organization = response.data
          this.start = 3
          this.dialog = true
        })
        .catch(err => {
          console.log(err.response)
        })
        .finally(() => {
        // this.$store.commit(mutations.LOADING_COMPLETE, `ORG_PROFILE_${this.openByQuery}`)
        })
    }
  },
  components: {
    OnboardWizard
    // IndoorClubWizard
  },
  watch: {
    openByQuery: function (val) {
      if (val) {
        this.load()
      }
    }
  }
}
</script>

<style>

</style>
